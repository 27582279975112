import ServiceBase from "./ServiceBase";
import { TrinsicOptions, TrustRegistryDefinition } from "./proto";
import * as proto from "./proto";

import type { Client as BrowserClient } from "nice-grpc-web";

export class TrustRegistryService extends ServiceBase {
    client: BrowserClient<typeof TrustRegistryDefinition>;

    constructor(options?: TrinsicOptions) {
        super(options);

        this.client = this.createClient(TrustRegistryDefinition);
    }
// BEGIN Code generated by protoc-gen-trinsic. DO NOT EDIT.
// target: /home/runner/work/sdk/sdk/web/src/TrustRegistryService.ts

  /** Register an authoritative issuer for a credential schema */
  public async registerMember(request: proto.RegisterMemberRequest): Promise<proto.RegisterMemberResponse> {
    
    return this.client.registerMember(request, {
      metadata: await this.buildMetadata(proto.RegisterMemberRequest.encode(request).finish())
    });
  }
  /** Removes an authoritative issuer for a credential schema from the trust registry */
  public async unregisterMember(request: proto.UnregisterMemberRequest): Promise<proto.UnregisterMemberResponse> {
    
    return this.client.unregisterMember(request, {
      metadata: await this.buildMetadata(proto.UnregisterMemberRequest.encode(request).finish())
    });
  }
  /** Fetch the status of a member for a given credential schema in a trust registry */
  public async getMemberAuthorizationStatus(request: proto.GetMemberAuthorizationStatusRequest): Promise<proto.GetMemberAuthorizationStatusResponse> {
    
    return this.client.getMemberAuthorizationStatus(request, {
      metadata: await this.buildMetadata(proto.GetMemberAuthorizationStatusRequest.encode(request).finish())
    });
  }
  /** Fetch the ecosystem's authorized issuers and the respective templates against which it can issue */
  public async listAuthorizedMembers(request: proto.ListAuthorizedMembersRequest): Promise<proto.ListAuthorizedMembersResponse> {
    
    return this.client.listAuthorizedMembers(request, {
      metadata: await this.buildMetadata(proto.ListAuthorizedMembersRequest.encode(request).finish())
    });
  }
  /** Get member for a given did in a trust registry */
  public async getMember(request: proto.GetMemberRequest): Promise<proto.GetMemberResponse> {
    
    return this.client.getMember(request, {
      metadata: await this.buildMetadata(proto.GetMemberRequest.encode(request).finish())
    });
  }
// END Code generated by protoc-gen-trinsic. DO NOT EDIT.
}
